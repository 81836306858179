<style scoped>
	.loading-box{min-height: 400px;}
</style>
<template>
	<div class="border rounded p-5 mt-5 mx-auto bg-td-section-gray" style="max-width: 600px;box-shadow: 3px 3px #919191;">
		<h1>Login</h1>
		<h5>Administrators Only</h5>
		<hr />
		<div class="loading-box text-center" v-show="loading">
			<div class="spinner-border text-blue mt-5" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
			<div>Sending verification code by email...</div>
		</div>
		<div v-show="!loading">
			<div class="mb-3">
				<label for="exampleInputEmail1" class="form-label">Email address</label>
				<input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="email" @keyup="submitme">
				<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
			</div>
			<div class="mb-3">
				<label for="exampleInputPassword1" class="form-label">Password</label>
				<input type="password" class="form-control" id="exampleInputPassword1" v-model="password" @keyup="submitme">
			</div>
			<div class="mt-2 mb-2" v-if="msg">
				<div class="badge bg-danger">{{msg}}</div>
			</div>
			<div class="row">
				<div class="col">
					<a href="javascript:void(0)" @click="$router.push('/password/reset')">Forgot Your Password?</a>
				</div>
				<div class="col mb-3 text-end">
					<button type="button" class="btn btn-primary" @click="login">Login</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	Name: 'LoginForm',
	data(){return{
		email: null, 
		password: null, 
		msg: '',
		loading: false,
	}},
	methods: {
		submitme(e){
			if( e.key === 'Enter' || e.keyCode === 13 )
				this.login()
		},
		login(){
			// sends login submission to the server
			let v = this
			v.loading = true
			v.axios.post('/api/login',{email:v.email,password:v.password}).then(response=>{
				if( !response )
				{
					v.msg = "Login or Password Invalid, Try again or contact an administrator for help."
					return
				}
				if(response.data.token)
				{
					// console.log('Skip OTP is '+String(response.data.skip_otp))
					let skip = parseInt(response.data.skip_otp) == 1;
					v.$emit('login',response.data.token,skip)
				}
			}).catch(error=>{
				if( error.response.data )
				{
					v.msg = error.response.data.messages.error
				}
			}).finally(()=>{
				v.loading = false
			})
		},
	},
}
</script> 
